import React from "react";
import "./CSS/SocialButtons.css"

import facebook from "./MEDIA/facebook.png"
import tiktok from "./MEDIA/tiktok.png"
import instagram from "./MEDIA/instagram.png"

const SocialButtons = () => {
    return(
        <div className="SocialButtons__main_div">
            <a target="_blank" href="https://www.facebook.com/JJCBARANDRESTAURANTGEDE">
                <img src={facebook} alt="facebook" className="SocialButtons__social_img"/>
            </a>
            <a target="_blank" href="https://www.instagram.com/jjc.bar.restaurant_gede/">
                <img src={instagram} alt="instagram" className="SocialButtons__social_img"/>
            </a>
            <a target="_blank" href="https://www.tiktok.com/@jbdaninja1?is_from_webapp=1&sender_device=pc">
                <img src={tiktok} alt="tiktok" className="SocialButtons__social_img"/>
            </a>
        </div>
    )
}

export default SocialButtons