import React, {useEffect} from "react"
import "./CSS/Safari.css"
import NavWrapper from "../../Components/NavWrapper/NavWrapper"
import Navbar from "../../Components/Navbar/Navbar"
import Footer from "../../Components/Footer/Footer"
import Ticket_CARD from "../../Components/Ticket_CARD/Ticket_CARD"

import { useSelector } from "react-redux"

const Safari = () => {

    const lang_index = useSelector(state => state.langReducer)
    const safari_texts = require("./JSON/safari_texts.json")

    useEffect(() => {
        document.querySelector(".Navbar__main_div").scrollIntoView({ behavior: 'smooth' })
    }, [])
    
    return(
        <>
            <NavWrapper title = "Safari" image = "MEDIA/safari.webp">
                <Navbar/>
            </NavWrapper>
            <div className="Safari__main_div">
                <div className="Safari__title_div">
                    <h1 className="Safari__title">
                        {safari_texts[lang_index.lang].title}
                    </h1>
                    <p className="Safari__par">
                        {safari_texts[lang_index.lang].par}
                    </p>
                </div>
                <div className="Safari__second_div">
                    <Ticket_CARD index = {3} img = "MEDIA/Tickets/Safari/img_3.jpg" title = "SAFARI MARATHON (TSAVO EAST)" par = {safari_texts[lang_index.lang].cards.card_4} price = "€50.00" page = "safari" />
                    <Ticket_CARD index = {4} img = "MEDIA/Tickets/Safari/img_4.jpg" title = "SAVANA (TSAVO EAST)" par = {safari_texts[lang_index.lang].cards.card_5} price = "€50.00" page = "safari"/>
                    <Ticket_CARD index = {9} img = "MEDIA/Tickets/Safari/img_9.jpg" title = "MASAI MARA BY FLIGHT" par = {safari_texts[lang_index.lang].cards.card_10} price = "€50.00" page = "safari" />
                    <Ticket_CARD index = {5} img = "MEDIA/Tickets/Safari/img_5.jpg" title = "SAFARI TSAVO EAST AMBOSELI" par = {safari_texts[lang_index.lang].cards.card_6} price = "€50.00" page = "safari"/>
                    <Ticket_CARD index = {1} img = "MEDIA/Tickets/Safari/img_1.jpg" title = "MOMBASA TAITA HILLS TSAVO EAST" par = {safari_texts[lang_index.lang].cards.card_2} price = "€50.00" page = "safari"/>
                    <Ticket_CARD index = {11} img = "MEDIA/Tickets/Safari/img_11.jpg" title = "MASAI MARA BY FLIGHT MALINDI" par = {safari_texts[lang_index.lang].cards.card_12} price = "€50.00" page = "safari" />
                    <Ticket_CARD index = {8} img = "MEDIA/Tickets/Safari/img_8.jpg" title = "TSAVO EAST/AMBOSELLI/TSAVO OVEST" par = {safari_texts[lang_index.lang].cards.card_9} price = "€50.00" page = "safari" />
                    <Ticket_CARD index = {7} img = "MEDIA/Tickets/Safari/img_7.jpg" title = "MOMBASA/TAITA HILLS/ZIWANI/TSAVO EAST" par = {safari_texts[lang_index.lang].cards.card_8} price = "€50.00" page = "safari" />
                    <Ticket_CARD index = {12} img = "MEDIA/Tickets/Safari/img_12.jpg" title = "MASAI MARA E LAKE NAKURU" par = {safari_texts[lang_index.lang].cards.card_13} price = "€50.00" page = "safari" />
                    <Ticket_CARD index = {2} img = "MEDIA/Tickets/Safari/img_2.jpg" title = "MASAI MARA" par = {safari_texts[lang_index.lang].cards.card_3} price = "€50.00" page = "safari"/>
                    <Ticket_CARD index = {13} img = "MEDIA/Tickets/Safari/img_13.jpg" title = "MOMBASA-TAITA HILLS/AMBOSELLI /TSAVO OVEST/TSAVO-EST-WATAMU" par = {safari_texts[lang_index.lang].cards.card_14} price = "€50.00" page = "safari" />
                    <Ticket_CARD index = {0} img = "MEDIA/Tickets/Safari/img_0.jpg" title = "MOMBASA TAITA AMBOSELI TSAVO OVEST/TSAVO EAST" par = {safari_texts[lang_index.lang].cards.card_1} price = "€50.00" page = "safari" />
                    <Ticket_CARD index = {10} img = "MEDIA/Tickets/Safari/img_10.jpg" title = "NAIROBI/MASAI MARA/LAGO NAKURU/AMBOSELI/TSAVO OVEST/TSAVO EST-WATAMU" par = {safari_texts[lang_index.lang].cards.card_11} price = "€50.00" page = "safari" />
                    <Ticket_CARD index = {6} img = "MEDIA/Tickets/Safari/img_6.jpg" title = "NAIROBI/MASAI MARA/LAGO NAKURU/AMBOSELI/TAITAHILLS(PALAFITTE) / TSAVO EST-WATAMU" par = {safari_texts[lang_index.lang].cards.card_7} price = "€50.00" page = "safari" />
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Safari