import React from "react";
import "./CSS/HomeFirstC.css"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import parse from 'html-react-parser';

const HomeFirstC = (props) => {

    let navigate = useNavigate()

    const lang_index = useSelector(state => state.langReducer)
    const hfc_texts = require("./JSON/hfc_texts.json")

    return(
        <div className="HomeFirstC__main_div">
            {props.children}
            <div className="HomeFirstC__second_div">
                <div className="HomeFirstC__title">
                    <h1>JJC Adventure Kenya</h1>
                </div>
                <div className="HomeFirstC__par">
                    {parse(hfc_texts[lang_index.lang].title)}
                </div>
                <div className="HomeFirstC__but_div">
                    <div className="HomeFirstC__second_but_div">
                        <button className="HomeFirstC__button" onClick={() => navigate("/safari")}>
                            Safari
                        </button>
                        <button className="HomeFirstC__button" onClick={() => navigate("/escursioni")}>
                            {hfc_texts[lang_index.lang].but1}
                        </button>
                        <button className="HomeFirstC__button" onClick={() => navigate("/ristorante")}>
                            {hfc_texts[lang_index.lang].but2}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeFirstC;