import React, { useRef, useState } from "react";
import "./CSS/LightBox.css";
import right from "./MEDIA/right.png";
import left from "./MEDIA/left.png";
import close from "./MEDIA/close.png";

const LightBox = (props) => {

  const [currentImgIndex, setCurrentImgIndex] = useState(0);
  const ref1 = useRef();

  const ImgClick = (index) => {
    FocusMainDivToggle();
    setCurrentImgIndex(index);
  };

  const FocusMainDivToggle = () => {
    ref1.current.classList.toggle("LightBox__focus_main_div_toggle");
  };

  return (
    <div className="LightBox__row">
      {props.images.map(arr => {
        return (
          <div
            key={(Math.random() + 1).toString(36).substring(7)}
            className="LightBox__column"
          >
            {arr.map(ele => {
              return (
                <img
                  key={ele.index}
                  src={ele.image_path}
                  alt=""
                  className="LightBox__img"
                  onClick={() => {
                    ImgClick(ele.index);
                  }}
                />
              );
            })}
          </div>
        );
      })}

      <div className="LightBox__focus_main_div" ref={ref1}>
        <div className="LightBox__focus_navbar">
          <p className="LightBox__index_par">
            {currentImgIndex + 1} / {props.images.flat().length}
          </p>
          <img
            src={close}
            alt="close"
            className="LightBox__close"
            onClick={FocusMainDivToggle}
          />
        </div>
        <div className="LightBox__focus_under_div">
          <div
            className="LightBox__arrow_div"
            onClick={() => {
              if (currentImgIndex > 0) setCurrentImgIndex(currentImgIndex - 1);
            }}
          >
            <img src={left} alt="left" className="LightBox__arrow_img" />
          </div>
          <div className="LightBox__img_main_div">
            <div className="LightBox__img_second_div">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundSize: "contain",
                  backgroundImage:
                    "url('" +
                    props.images.flat()[currentImgIndex].image_path +
                    "')",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </div>
          </div>
          <div
            className="LightBox__arrow_div"
            onClick={() => {
              if (currentImgIndex < props.images.flat().length - 1)
                setCurrentImgIndex(currentImgIndex + 1);
            }}
          >
            <img src={right} alt="right" className="LightBox__arrow_img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LightBox;
