import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import WA_Button from "../WA_Button/WA_Button";
import SocialButtons from "../SocialButtons/SocialButtons";

import { useSelector, useDispatch } from "react-redux";

import logo from "./MEDIA/africa.png"
import cart from "./MEDIA/cart.png"
import close from "./MEDIA/close.png"
import basket from "./MEDIA/basket.png"
import left_arrow from "./MEDIA/left_arrow.png"

import "./CSS/Navbar.css"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Ban from "../Ban/Ban";

const NoElementsInCart = () => {

    const lang_index = useSelector(state => state.langReducer)
    const navbar_texts = require("./JSON/navbar_texts.json")

    return(
        <div className="NE_main_div">
            <img src={basket} alt="basket" className="NE_img_icon" />
            <p className="NE_text">{navbar_texts[lang_index.lang].cart.cae}</p>
        </div>
    )
}

const CART_Card = (props) => {

    const dispatch = useDispatch()
    const cartitems = useSelector(state => state.cartReducer)

    const removeItems = () => {
        if (cartitems.products[props.index].quantity === 1){
            dispatch({type:"removeItem", index:props.index})
        }else{
            dispatch({type:"removeItems", index:props.index, quantity:1})
        }
    }

    const removeOneProd = () => {
        dispatch({type:"removeItem", index:props.index})
    }

    return(
        <div className="Navbar__cart_card_main_div">
            <div className="Navbar__cart_card_remove_div">
                <img src={close} alt="remove" className="Navbar__cart_card_remove_button" onClick={() => removeOneProd()} />
            </div>
            <div className="Navbar__cart_card_second_div">
                <h1 className="Navbar__cart_card_title">
                    {props.title}
                </h1>
                <div className="Navbar__cart_card_buttons_div">
                    <button className="Navbar__cart_card_buttons_but" onClick={removeItems} >
                        -
                    </button>
                    <p className="Navbar__cart_card_buttons_quan">
                        {props.quantity}
                    </p>
                    <button className="Navbar__cart_card_buttons_but" onClick={() => {dispatch({type:"addItems", index:props.index, quantity:1})}}>
                        +
                    </button>
                </div>
                <h2 className="Navbar__cart_card_price">
                    €{props.price * props.quantity}
                </h2>
            </div>
        </div>
    )
}

const Navbar = (props) => {

    const lang_index = useSelector(state => state.langReducer)
    const navbar_texts = require("./JSON/navbar_texts.json")

    const navigate = useNavigate()
    let ref1 = useRef()
    let ref2 = useRef()
    let ref3 = useRef()
    let ref4 = useRef()
    let ref5 = useRef()
    let ref6 = useRef()

    let ref7 = useRef()

    const cartitems = useSelector(state => state.cartReducer)
    var dispatch = useDispatch()
    
    const NavbarLangMenuToggle = () => {
        ref1.current.classList.toggle("Navbar__curtain_menu_toggle")
        ref4.current.classList.toggle("Navbar__black_background_toggle")
    }
    const NavbarButtonsMenuToggle = () => {
        ref2.current.classList.toggle("Navbar__buttons_div_toggle")
        ref4.current.classList.toggle("Navbar__black_background_toggle")
    }
    const NavbarCartMenuToggle = () => {
        ref3.current.classList.toggle("Navbar__curtain_cart_toggle")
        ref4.current.classList.toggle("Navbar__black_background_toggle")
    }
    const NavbarCartMenuLongToggle = () => {
        ref6.current.classList.toggle("Navbar__curtain_cart_long_toggle")
    }
    const NavbarCartMenuLoadingToggle = () => {
        ref5.current.classList.toggle("Navbar__curtain_cart_loading_toggle")
    }

    const ChangeLang = (index_lang) => {
        //localStorage.setItem("lang", index_lang)
        dispatch({type:"setLang", lang:index_lang})
        NavbarLangMenuToggle()
    }

    /* const CreatePaymentLink = () => {

        if (cartitems.products.length > 0){

            ref5.current.classList.toggle("Navbar__curtain_cart_loading_toggle")
            let json_data = {}
            cartitems.products.forEach((ele, index) => {
                json_data[`line_items[${index}][price]`] = ele.id_product
                json_data[`line_items[${index}][quantity]`] = ele.quantity
            })

            let body = new URLSearchParams(json_data).toString()

            Middleware.SendRequestCreateLink(body).then(json_response => {
                ref5.current.classList.toggle("Navbar__curtain_cart_loading_toggle")
                dispatch({type : "resetALL"})
                window.open(json_response.url, '_blank').focus();
            }) 

        }
        
    } */

    const notifySuccess = () => {
        
        toast.success('Acquisto avvenuto con successo!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    }

    const PaypalButton = () => {
        if (cartitems.products.length > 0){
            
            NavbarCartMenuLongToggle()
            ref7.current.innerHTML = ""

            window.paypal.Buttons({

                createOrder: function(data, actions) {
                    return actions.order.create({
                        "purchase_units": [{
                            "amount": {
                              "currency_code": "EUR",
                              "value": cartitems.total,
                              "breakdown": {
                                "item_total": { 
                                  "currency_code": "EUR",
                                  "value": cartitems.total
                                }
                              }
                            },
                            "items": (() => {
                                
                                let arr_items = []
                                cartitems.products.forEach((ele, index) => {
                                    arr_items.push(
                                        {
                                            "name": ele.product_name,
                                            "unit_amount": {
                                              "currency_code": "EUR",
                                              "value": ele.price
                                            },
                                            "quantity": ele.quantity
                                        }
                                    )
                                })

                                return arr_items
                            })()
                          }]
                    });
                },
    
                onApprove: function(data, actions) {
                    return actions.order.capture().then(function(orderData) {
                        notifySuccess()
                        dispatch({type : "resetALL"})
                        NavbarCartMenuLongToggle()
                    });
                }
    
            }).render(ref7.current);
        }
    }

    return(
        <div className="Navbar__main_div" style={{"backgroundColor":props.bgco}}>
            <div className="Navbar__second_div">
                <div className="Navbar__burger_menu_div" onClick={NavbarButtonsMenuToggle}>
                    <div className="Navbar__burger_div"></div>
                    <div className="Navbar__burger_div"></div>
                    <div className="Navbar__burger_div"></div>
                </div>
                <div className="Navbar__logo_div">
                    <img src={logo} alt="logo" className="Navbar__logo_img" onClick={() => navigate("/")} />
                </div>
                <div className="Navbar__buttons_div" ref={ref2}>
                    <div className="Navbar__button_close_navbar_div">
                        <img src={close} alt="close" className="Navbar__button_close_navbar_img" onClick={NavbarButtonsMenuToggle} />
                    </div>
                    <button className="Navbar__button_navbar" onClick={() => navigate("/chi_siamo")}>{navbar_texts[lang_index.lang].but_1}</button>
                    <button className="Navbar__button_navbar" onClick={() => navigate("/safari")}>{navbar_texts[lang_index.lang].but_2}</button>
                    <button className="Navbar__button_navbar" onClick={() => navigate("/escursioni")}>{navbar_texts[lang_index.lang].but_7}</button>
                    <button className="Navbar__button_navbar" onClick={() => navigate("/ristorante")}>{navbar_texts[lang_index.lang].but_9}</button>
                    <button className="Navbar__button_navbar" onClick={() => navigate("/alloggi")}>{navbar_texts[lang_index.lang].but_8}</button>
                    <button className="Navbar__button_navbar" onClick={() => navigate("/servizi")}>{navbar_texts[lang_index.lang].but_3}</button>
                    <button className="Navbar__button_navbar" onClick={() => navigate("/faq")}>FAQ</button>
                    <button className="Navbar__button_navbar" onClick={() => navigate("/galleria")}>{navbar_texts[lang_index.lang].but_5}</button>
                    <button className="Navbar__button_navbar" onClick={() => navigate("/contattaci")}>{navbar_texts[lang_index.lang].but_6}</button>
                </div>
                <div className="Navbar__cart_and_lang_div">
                    <p className="Navbar__cart_num_prod">
                        {cartitems.products.length}
                    </p>
                    <img src={cart} alt="logo" className="Navbar__cart_img" onClick={NavbarCartMenuToggle} />
                    <button className="Navbar__lang_button" onClick={NavbarLangMenuToggle}>
                        {lang_index.lang}
                    </button>
                </div>
                <div className="Navbar__curtain_menu" ref={ref1}>
                    <div className="Navbar__curtain_menu_exit_div">
                        <img src={close} alt="close" className="Navbar__curtain_menu_exit_img" onClick={NavbarLangMenuToggle} />
                    </div>
                    <button className="Navbar__curtain_menu_lang_button" onClick={() => ChangeLang("IT")}>IT</button>
                    <button className="Navbar__curtain_menu_lang_button" onClick={() => ChangeLang("FR")}>FR</button>
                    <button className="Navbar__curtain_menu_lang_button" onClick={() => ChangeLang("EN")}>EN</button>
                    <button className="Navbar__curtain_menu_lang_button" onClick={() => ChangeLang("DE")}>DE</button>
                </div>
                <div className="Navbar__curtain_cart" ref={ref3}>
                    <div className="Navbar__curtain_cart_long" ref={ref6}>
                        <div className="Navbar__curtain_cart_prin">
                            <div className="Navbar__curtain_cart_exit_div">
                                <img src={close} alt="close" className="Navbar__curtain_cart_exit_img" onClick={NavbarCartMenuToggle} />
                            </div>
                            <div className="Navbar__curtain_cart_prods_list_div">
                                {
                                    cartitems.products.length > 0 ? (
                                        cartitems.products.map((ele, index) => {
                                            return <CART_Card key={ele.id_product} title = {ele.product_name} quantity = {ele.quantity} price = {ele.price} index={index} /> 
                                        })
                                    ) : (
                                        <NoElementsInCart/>
                                    )
                                }
                            </div>
                            <h2 className="Navbar__par_total_price_cart">
                                {navbar_texts[lang_index.lang].cart.totale}: €{cartitems.total}
                            </h2>
                            <div className="Navbar__curtain_cart_submit_div">
                                <button className="Navbar__curtain_cart_submit_button" onClick={() => PaypalButton()} >
                                    {navbar_texts[lang_index.lang].cart.gpay}
                                </button>
                            </div>
                            <div className="Navbar__curtain_cart_loading" ref = {ref5}>
                                <div className="lds-dual-ring"></div>
                            </div>
                        </div>
                        <div className="Navbar__curtain_cart_paypal_buttons">
                            <div className="Navbar__curtain_cart_exit_div">
                                <img src={left_arrow} alt="close" className="Navbar__curtain_cart_exit_img" onClick={NavbarCartMenuLongToggle} />
                            </div>
                            <div className="Navbar__curtain_cart_paypal_buttons_div" ref={ref7}>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="Navbar__black_background" ref = {ref4}/>
            </div>
            <ToastContainer />
            <WA_Button/>
            <SocialButtons/>
            <Ban/>
        </div>
    )
}

export default Navbar;