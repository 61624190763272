import React, {useEffect} from "react";
import "./CSS/Galleria.css"
import NavWrapper from "../../Components/NavWrapper/NavWrapper";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";

import LightBox from "../../Components/LightBox/LightBox";

import { useSelector } from "react-redux";

const Galleria = () => {

    const lang_index = useSelector(state => state.langReducer)
    const galleria_texts = require("./JSON/galleria_texts.json")

    useEffect(() => {
        document.querySelector(".Navbar__main_div").scrollIntoView({ behavior: 'smooth' })
    }, [])

    const images = [
        [
            {image_path:"MEDIA/Gallery/img1.jpg", index:0},
            {image_path:"MEDIA/Gallery/img2.jpg", index:1},
            {image_path:"MEDIA/Gallery/img3.jpg", index:2},
            {image_path:"MEDIA/Gallery/img4.jpg", index:3},
            {image_path:"MEDIA/Gallery/img5.jpeg", index:4},
            {image_path:"MEDIA/Gallery/img6.jpeg", index:5},
            {image_path:"MEDIA/Gallery/img7.jpeg", index:6},
            {image_path:"MEDIA/Gallery/img13.jpeg", index:7},
        ],
        [
            {image_path:"MEDIA/Gallery/img8.jpeg", index:8},
            {image_path:"MEDIA/Gallery/img9.jpg", index:9},
            {image_path:"MEDIA/Gallery/img10.jpeg", index:10},
            {image_path:"MEDIA/Gallery/img11.jpeg", index:11},
            {image_path:"MEDIA/Gallery/img12.jpeg", index:12},
        ], 
        [
            {image_path:"MEDIA/Gallery/img14.jpeg", index:13},
            {image_path:"MEDIA/Gallery/img15.jpeg", index:14},
            {image_path:"MEDIA/Gallery/img16.jpeg", index:15},
            {image_path:"MEDIA/Gallery/img17.jpeg", index:16},
            {image_path:"MEDIA/Gallery/img18.jpeg", index:17},
            {image_path:"MEDIA/Gallery/img19.jpeg", index:18},
        ],
        [
            {image_path:"MEDIA/Gallery/img20.jpeg", index:19},
            {image_path:"MEDIA/Gallery/img21.jpeg", index:20},
            {image_path:"MEDIA/Gallery/img22.jpeg", index:21},
            {image_path:"MEDIA/Gallery/img23.jpeg", index:22},
            {image_path:"MEDIA/Gallery/img24.jpeg", index:23},
            {image_path:"MEDIA/Gallery/img25.jpeg", index:24},
            {image_path:"MEDIA/Gallery/img26.jpeg", index:25},
            {image_path:"MEDIA/Gallery/img27.jpeg", index:26},
            {image_path:"MEDIA/Gallery/img28.jpeg", index:27},
            {image_path:"MEDIA/Gallery/img29.jpeg", index:28},
            {image_path:"MEDIA/Gallery/img30.jpeg", index:29},
        ]
    ]

    return(
        <>
            <NavWrapper title = {galleria_texts[lang_index.lang].navwraptext} image = "MEDIA/galleria.webp">
                <Navbar/>
            </NavWrapper>
            <div className="Galleria__main_div">
                <div className="Galleria__title_div">
                    <h1 className="Galleria__title">
                        {galleria_texts[lang_index.lang].title}
                    </h1>
                    <p className="Galleria__par">
                        {galleria_texts[lang_index.lang].par}
                    </p>
                </div>
                <div className="row">
                    <div className="col d-flex justify-content-center">
                        <video className="Galleria__video_player" controls src="MEDIA/Gallery/video/1.mp4"></video>
                    </div>
                    <div className="col d-flex justify-content-center">
                        <video className="Galleria__video_player" controls src="MEDIA/Gallery/video/2.mp4"></video>
                    </div>
                    <div className="col d-flex justify-content-center">
                        <video className="Galleria__video_player" controls src="MEDIA/Gallery/video/3.mp4"></video>
                    </div>
                    <div className="col d-flex justify-content-center">
                        <video className="Galleria__video_player" controls src="MEDIA/Gallery/video/4.mp4"></video>
                    </div>
                    <div className="col d-flex justify-content-center">
                        <video className="Galleria__video_player" controls src="MEDIA/Gallery/video/5.mp4"></video>
                    </div>
                </div>
                <div className="Galleria__second_div">
                    <LightBox images = {images} />
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Galleria