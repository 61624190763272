import React from "react";
import "./CSS/Contacts.css"
import { useRef } from "react";
import emailjs from '@emailjs/browser';

import { useSelector } from "react-redux";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contacts = () => {

    const lang_index = useSelector(state => state.langReducer)
    const contacts_texts = require("./JSON/contacts_texts.json")

    let ref1 = useRef()
    let ref2 = useRef()

    const sendEmail = (e) => {
        
        e.preventDefault();

        if (![...ref1.current.querySelectorAll("input")].every(ele => {
            return ele.value.trim().length > 0;
        })){
            return;
        }

        if (ref1.current.querySelector("textarea").value.trim() === "") {return}

        ref2.current.classList.toggle("Contacts__loading_background_toggle")

        emailjs.sendForm('service_zi9tnch', 'template_xke80mm', ref1.current, 'SxWhbg-YxSJK0rdia').then((result) => {
            notifySuccess()
        }, (error) => {
            notifyError()
        }).finally(() => {
            ref1.current.reset()
            ref2.current.classList.toggle("Contacts__loading_background_toggle")
        })

    }

    const notifySuccess = () => {
        
        toast.success(contacts_texts[lang_index.lang].oksent, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });

    }

    const notifyError = () => {

        toast.error(contacts_texts[lang_index.lang].noksent, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });
        
    }

    return(
        <div className="Contacts__main_div">
            <div className="Contacts__second_div">
                <h1 className="Contacts__title">{contacts_texts[lang_index.lang].title}</h1>
                <form className="Contacts__form_main" ref = {ref1}>
                    <input type="text" className="Contacts__input_text" placeholder={contacts_texts[lang_index.lang].name} name="user_name" />
                    <input type="text" className="Contacts__input_text" placeholder={contacts_texts[lang_index.lang].email} name="user_email"/>
                    <input type="text" className="Contacts__input_text" placeholder={contacts_texts[lang_index.lang].oggetto} name="object" />
                    <textarea className="Contacts__textarea" name="message">

                    </textarea>
                    <button className="Contacts__submit_button" onClick={(e) => sendEmail(e)} >
                        {contacts_texts[lang_index.lang].but}
                    </button>
                </form>
            </div>
            <ToastContainer />
            <div className="Contacts__loading_background" ref = {ref2}>
                <div className="lds-dual-ring"></div>
            </div>
        </div>
    )
}

export default Contacts;