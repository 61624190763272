import React, {useEffect} from "react";
import "./CSS/Servizi.css"
import NavWrapper from "../../Components/NavWrapper/NavWrapper";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import Contacts from "../../Components/Contacts/Contacts";

import map from "./MEDIA/map.png"
import diver from "./MEDIA/diver.png"
import exchange from "./MEDIA/exchange.png"
import car from "./MEDIA/car.png"
import sim from "./MEDIA/sim.png"
import world from "./MEDIA/world.png"
import kite from "./MEDIA/kitesurfing.png"
import fork from "./MEDIA/fork.png"

import parse from 'html-react-parser';

import { useSelector } from "react-redux";

const SER_card = (props) => {
    return(
        <div className="Servizi__card">
            <div className="Servizi__card_img_div">
                <img src={props.img} alt="img" className="Servizi__card_img" />
            </div>
            <h1 className="Servizi__card_title">
                {props.title}
            </h1>
            <div className="Servizi__card_par">
                {props.par}
            </div>
        </div>
    )
}

const Servizi = () => {

    const lang_index = useSelector(state => state.langReducer)
    const servizi_texts = require("./JSON/servizi_texts.json")

    useEffect(() => {
        document.querySelector(".Navbar__main_div").scrollIntoView({ behavior: 'smooth' })
    }, [])

    return(
        <>
            <NavWrapper title = {servizi_texts[lang_index.lang].navwraptext} image = "MEDIA/servizi.webp">
                <Navbar/>
            </NavWrapper>
            <div className="Servizi__main_div">
                <div className="Servizi__title_div">
                    <h1 className="Servizi__title">
                        {servizi_texts[lang_index.lang].title}
                    </h1>
                    <p className="Servizi__par">
                        {servizi_texts[lang_index.lang].par}
                    </p>
                </div>
                <div className="Servizi__grid">
                   
                    <SER_card title = {servizi_texts[lang_index.lang].cards.card_1.title} par = {parse(servizi_texts[lang_index.lang].cards.card_1.par)} img = {kite} />
                    <SER_card title = {servizi_texts[lang_index.lang].cards.card_2.title} par = {parse(servizi_texts[lang_index.lang].cards.card_2.par)} img = {car} />
                    <SER_card title = {servizi_texts[lang_index.lang].cards.card_3.title} par = {parse(servizi_texts[lang_index.lang].cards.card_3.par)} img = {fork} />
                    <SER_card title = {servizi_texts[lang_index.lang].cards.card_4.title} par = {parse(servizi_texts[lang_index.lang].cards.card_4.par)} img = {exchange} />
                    <SER_card title = {servizi_texts[lang_index.lang].cards.card_5.title} par = {parse(servizi_texts[lang_index.lang].cards.card_5.par)} img = {sim} />
                    <SER_card title = {servizi_texts[lang_index.lang].cards.card_6.title} par = {parse(servizi_texts[lang_index.lang].cards.card_6.par)} img = {world} />
                    
                </div>
            </div>
            <Contacts/>
            <Footer/>
        </>
    )
}

export default Servizi;