import React, {useEffect} from "react";
import "./CSS/ChiSiamo.css"
import NavWrapper from "../../Components/NavWrapper/NavWrapper";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import LightBox from "../../Components/LightBox/LightBox";

import parse from 'html-react-parser';

/* import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css'; */

import { useSelector } from "react-redux";

const ChiSiamo = () => {

    const lang_index = useSelector(state => state.langReducer)
    const chisiamo_texts = require("./JSON/chisiamo_texts.json")


    const images = [
        [
            // Attenzione!! Si inzia a contare da 0 (index:0), altrimenti il componente LightBox va in overflow
            {image_path:"MEDIA/chi_siamo/img1.jpg", index:0},
            {image_path:"MEDIA/chi_siamo/img2.jpeg", index:1},
            {image_path:"MEDIA/chi_siamo/img3.jpeg", index:2},
        ],
        [
            {image_path:"MEDIA/chi_siamo/img6.jpg", index:3},
            {image_path:"MEDIA/chi_siamo/img4.jpeg", index:4},
            {image_path:"MEDIA/chi_siamo/img5.jpeg", index:5},
        ]
    ]

    useEffect(() => {
        document.querySelector(".Navbar__main_div").scrollIntoView({ behavior: 'smooth' })
    }, [])

    return(
        <>
            <NavWrapper title = {chisiamo_texts[lang_index.lang].navwraptext} image = "./MEDIA/chi__siamo.jpg" myclass="bg-bottom">
                <Navbar/>
            </NavWrapper>
            <div className="ChiSiamo__pres_div">
                <h1 className="ChiSiamo__pres_title">
                    {chisiamo_texts[lang_index.lang].sec_1.title}
                </h1>
                <div className="ChiSiamo__pres_second_div">
                    {/* <p className="ChiSiamo__pres_par">
                        {chisiamo_texts[lang_index.lang].sec_1.par}
                    </p> */}
                    {parse(chisiamo_texts[lang_index.lang].sec_1.par)}
                </div>
            </div>

            <LightBox images = {images} />

            {/* <div style={{"width":"80%", "height":"auto", "display":"flex", marginTop:"30px", marginBottom:"30px"}}>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="MEDIA/chi_siamo/img1.jpg"
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="MEDIA/chi_siamo/img2.jpg"
                            alt="Second slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </div> */}
            
            <div className="ChiSiamo__pres_serv_div">
                <h1 className="ChiSiamo__pres_serv_title">
                    {chisiamo_texts[lang_index.lang].sec_2.title}
                </h1>
                <div className="ChiSiamo__pres_serv_second_div">
                    <div className="ChiSiamo__pres_serv_card">
                        <h2 className="ChiSiamo__pres_serv_title_2">
                            {chisiamo_texts[lang_index.lang].sec_2.cards.card_1.title}
                        </h2>
                        <p className="ChiSiamo__pres_par">
                            {chisiamo_texts[lang_index.lang].sec_2.cards.card_1.par}
                        </p>
                    </div>
                    <div className="ChiSiamo__pres_serv_card">
                        <h2 className="ChiSiamo__pres_serv_title_2">
                            {chisiamo_texts[lang_index.lang].sec_2.cards.card_2.title}
                        </h2>
                        <p className="ChiSiamo__pres_par">
                            {chisiamo_texts[lang_index.lang].sec_2.cards.card_2.par}
                        </p>
                    </div>
                    <div className="ChiSiamo__pres_serv_card">
                        <h2 className="ChiSiamo__pres_serv_title_2">
                            {chisiamo_texts[lang_index.lang].sec_2.cards.card_3.title}
                        </h2>
                        <p className="ChiSiamo__pres_par">
                            {chisiamo_texts[lang_index.lang].sec_2.cards.card_3.par}
                        </p>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default ChiSiamo;