import React from "react";
import "./CSS/NavWrapper.css"

const NavWrapper = (props) => {
    return(
        <div className="NavWrapper__main_div" style={{"backgroundImage":"url(" + props.image + ")"}}>
            {props.children}
            <div className="NavWrapper__second_div">
                <h1 className="NavWrapper__title">
                    {props.title}
                </h1>
            </div>
        </div>
    )
}

export default NavWrapper;