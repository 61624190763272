import React, {useEffect} from "react"
import "./CSS/Contattaci.css"
import NavWrapper from "../../Components/NavWrapper/NavWrapper"
import Navbar from "../../Components/Navbar/Navbar"
import Contacts from "../../Components/Contacts/Contacts"
import Footer from "../../Components/Footer/Footer"
import Accordion from 'react-bootstrap/Accordion';

import { useSelector } from "react-redux"

import vis from "./MEDIA/vis.jpeg"

const AccItem = (props) => {
    return(
        <Accordion.Item eventKey={props.akey}>
            <Accordion.Header>{props.title}</Accordion.Header>
            <Accordion.Body>
                {props.body}
            </Accordion.Body>
        </Accordion.Item>
    )
}

const Contattaci = () => {

    const lang_index = useSelector(state => state.langReducer)
    const cont_texts = require("./JSON/cont_texts.json")

    useEffect(() => {
        document.querySelector(".Navbar__main_div").scrollIntoView({ behavior: 'smooth' })
    }, [])

    return(
        <>
            <NavWrapper title = {cont_texts[lang_index.lang].navwraptext} image = "MEDIA/contattaci.webp">
                <Navbar/>
            </NavWrapper>
            {/* <div className="Contattaci__df_main_div">
                <h1 className="Contattaci__df_title">
                    {cont_texts[lang_index.lang].sec_1.title}
                </h1>
                <div className="Contattaci__df_second_div">
                <Accordion>
                    <AccItem akey = "0" title = {cont_texts[lang_index.lang].sec_1.ars.ar_1.title} body = {cont_texts[lang_index.lang].sec_1.ars.ar_1.par} /> 
                    <AccItem akey = "1" title = {cont_texts[lang_index.lang].sec_1.ars.ar_2.title} body = {cont_texts[lang_index.lang].sec_1.ars.ar_2.par} /> 
                    <AccItem akey = "2" title = {cont_texts[lang_index.lang].sec_1.ars.ar_3.title} body = {cont_texts[lang_index.lang].sec_1.ars.ar_3.par} /> 
                    <AccItem akey = "3" title = {cont_texts[lang_index.lang].sec_1.ars.ar_4.title} body = {cont_texts[lang_index.lang].sec_1.ars.ar_4.par} /> 
                    <AccItem akey = "4" title = {cont_texts[lang_index.lang].sec_1.ars.ar_5.title} body = {cont_texts[lang_index.lang].sec_1.ars.ar_5.par} /> 
                    <AccItem akey = "5" title = {cont_texts[lang_index.lang].sec_1.ars.ar_6.title} body = {cont_texts[lang_index.lang].sec_1.ars.ar_6.par} /> 
                </Accordion>
                </div>
            </div> */}
                <div className="Contattaci__map_main_div">
                    <h1 className="Contattaci__map_title">
                        {cont_texts[lang_index.lang].sec_2.title}
                    </h1>
                    <div className="Contattaci_map_second_div">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1184.1840765024167!2d40.011842628561396!3d-3.3243310634682337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1815817727a65ec3%3A0xd8eed63fbb9024fa!2sJJC%20BAR%20AND%20RESTAURANT!5e0!3m2!1sit!2sit!4v1666128805178!5m2!1sit!2sit" width="80%" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" style={{"margin":"50px"}}></iframe>
                    </div>
                </div>
                <img src={vis} alt="carta_da_visita" style={{width:"100%", padding:20, maxWidth:700, height:"auto",marginBottom:30 }} />
                <Contacts/>
            <Footer/>
        </>
    )
}

export default Contattaci