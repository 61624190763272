import React, {useEffect, useState} from "react";
import "./CSS/ItemPage.css"
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";
import Contacts from "../Contacts/Contacts";
import parse from 'html-react-parser';

import { useSelector, useDispatch } from "react-redux";

const PAR = (props) => {
    return(
        <div className="ItemPage__par_main_div">
            <h1 className="ItemPage__par_title">
                {props.title}
            </h1>
            {parse(props.par)}
        </div>
    )
}

const ItemPage = (props) => {

    let ticket_info = require("./JSON/" + props.type + "_tickets.json")
    let {ticket_index} = useParams()
    const lang_index = useSelector(state => state.langReducer)
    const [quantity, setQuantity] = useState(1)
    const cartitems = useSelector(state => state.cartReducer)
    const dispatch = useDispatch()

    const AddItem = () => {
        
        let value = cartitems.products.every(ele => {
            return ticket_info[lang_index.lang][ticket_index].id_product != ele.id_product
        })

        if (value == true){
            dispatch({type:"setItem", newitem:{id_product:ticket_info[lang_index.lang][ticket_index].id_product, product_name:ticket_info[lang_index.lang][ticket_index].product_name, quantity:quantity, price:ticket_info[lang_index.lang][ticket_index].price, total_price:(quantity * ticket_info[lang_index.lang][ticket_index].price)}})
        }else{
            let index = cartitems.products.findIndex(ele => {
                return ele.id_product == ticket_info[lang_index.lang][ticket_index].id_product
            })
            dispatch({type:"addItems", index:index, quantity:quantity})
        }

        NavbarCartMenuToggle()

    }

    const NavbarCartMenuToggle = () => {
        document.querySelector(".Navbar__curtain_cart").classList.toggle("Navbar__curtain_cart_toggle")
        document.querySelector(".Navbar__black_background").classList.toggle("Navbar__black_background_toggle")
    }

    useEffect(() => {
        document.querySelector(".Navbar__main_div").scrollIntoView({ behavior: 'smooth' })
    }, [])

    return(
        <>
            <Navbar bgco = "rgb(153, 146, 93)" />
                <h1 className="ItemPage__title">
                    {ticket_info[lang_index.lang][ticket_index].title}
                </h1>
                <div className="ItemPage__main_div">
                    {
                        ticket_info[lang_index.lang][ticket_index].pars.map((ele, index) => {
                            return <PAR title = {ele.title} par = {ele.par} key={index} />
                        })
                    }
                </div>
                <div className="ItemPage__cart_add_buttons_div">
                    {/* <img src={"../../../MEDIA/Tickets/" + props.type + "/img_" + ticket_index + ".jpg"} alt="" className="ItemPage__img" /> */}
                    <div className="ItemPage__img" style={{backgroundImage:"url('" + "../../../MEDIA/Tickets/" + props.type + "/img_" + ticket_index + ".jpg" + "')"}} />
                    <div className="ItemPage__cart_add_buttons_second_div">
                        <div className="ItemPage__cart_add_buttons_buttons">
                            <button className="ItemPage__cart_add_buttons_but" onClick={() => {quantity > 1 && setQuantity(quantity - 1)}} >
                                -
                            </button>
                            <p className="ItemPage__cart_add_buttons_quan">
                                {quantity} {ticket_info[lang_index.lang].all}
                            </p>
                            <button className="ItemPage__cart_add_buttons_but" onClick={() => {setQuantity(quantity + 1)}}>
                                +
                            </button>
                        </div>
                        <div className="ItemPage__cart_add_buttons_submit_div">
                            <button className="ItemPage__cart_add_buttons_submit_button" onClick={() => AddItem()}>
                                {ticket_info[lang_index.lang]["button_1"]}
                            </button>
                        </div>
                    </div>
                </div>
            <Contacts/>
            <Footer/>
        </>
    )
}

export default ItemPage;