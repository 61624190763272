import React from "react";
import "./CSS/PageNotFound.css"

const PageNotFound = () => {
    return(
        <div className="PageNotFound__main_div">
            <h1 className="PageNotFound__text">
                Page not found (error 404)
            </h1>
        </div>
    )
}

export default PageNotFound