import React from "react";
import "./CSS/Ristorante.css"

import { useEffect } from "react";

import { useSelector } from "react-redux";

import NavWrapper from "../../Components/NavWrapper/NavWrapper";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import LightBox from "../../Components/LightBox/LightBox";

const Ristorante = () => {

    useEffect(() => {
        document.querySelector(".Navbar__main_div").scrollIntoView({ behavior: 'smooth' })
    }, [])

    const lang_index = useSelector(state => state.langReducer)
    const rest_texts = require("./JSON/rest_texts.json")

    const images = [
        [
            {image_path:"MEDIA/RES/1.jpeg", index:0},
            {image_path:"MEDIA/RES/2.jpeg", index:1},
            /* {image_path:"MEDIA/RES/3.jpeg", index:2}, */
            {image_path:"MEDIA/RES/4.jpeg", index:2},
            {image_path:"MEDIA/RES/5.jpeg", index:3},
            {image_path:"MEDIA/RES/14.jpeg", index:4},
            {image_path:"MEDIA/RES/21.jpeg", index:5},
            {image_path:"MEDIA/RES/23.jpeg", index:6},
        ],
        [
            {image_path:"MEDIA/RES/8.jpeg", index:7},
            {image_path:"MEDIA/RES/9.jpeg", index:8},
            {image_path:"MEDIA/RES/10.jpeg", index:9},
            {image_path:"MEDIA/RES/15.jpeg", index:10},
            {image_path:"MEDIA/RES/6.jpeg", index:11},
            {image_path:"MEDIA/RES/22.jpeg", index:12},
            
        ],
        [
            {image_path:"MEDIA/RES/11.jpeg", index:13},
            {image_path:"MEDIA/RES/12.jpeg", index:14},
            {image_path:"MEDIA/RES/13.jpeg", index:15},
            
        ],
        [
            {image_path:"MEDIA/RES/17.jpeg", index:16},
            {image_path:"MEDIA/RES/18.jpeg", index:17},
            {image_path:"MEDIA/RES/19.jpeg", index:18},
            {image_path:"MEDIA/RES/25.jpeg", index:19},
            {image_path:"MEDIA/RES/26.jpeg", index:20},
            /* {image_path:"MEDIA/RES/20.jpeg", index:22}, */
            
        ]
    ]

    const images2 = [
        [
            {image_path:"MEDIA/RES/CAFE/6.jpeg", index:0},
            {image_path:"MEDIA/RES/CAFE/1.jpeg", index:1},
            
        ],
        [
            {image_path:"MEDIA/RES/CAFE/3.jpeg", index:2},
            {image_path:"MEDIA/RES/CAFE/4.jpeg", index:3},
            {image_path:"MEDIA/RES/CAFE/2.jpeg", index:4},
        ],
        [
            {image_path:"MEDIA/RES/CAFE/5.jpeg", index:5},
        ]
    ]

    return(
        <>
            <NavWrapper title = {rest_texts[lang_index.lang].navwraptext} image = "MEDIA/ristorante.webp">
                <Navbar/>
            </NavWrapper>
            <div className="Safari__main_div">
                <div className="Ristorante__title_div">
                    <h1 className="Safari__title">
                        {rest_texts[lang_index.lang].title}
                    </h1>
                    <p className="RES__par">{rest_texts[lang_index.lang].par}</p>
                </div>
                <LightBox images = {images} />
                <h1>{rest_texts[lang_index.lang].title2}</h1>
                <p className="RES__par">{rest_texts[lang_index.lang].par3}</p>
                <LightBox images = {images2} />
            </div>
            <Footer/>
        </>
    )
}

export default Ristorante