import React, { useState } from "react";
import "./CSS/WA_Button.css";
import wabut from "./MEDIA/whatsapp.svg";
import trip from "./MEDIA/trip.png";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { useSelector } from "react-redux";

const WA_Button = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const lang_index = useSelector((state) => state.langReducer);
  const wa_texts = require("./JSON/wa_texts.json");

  return (
    <>
      <button className="WA_Button_link" onClick={handleShow}>
        <img src={wabut} alt="image" />
      </button>
      <a className="TRIPAD_Button_link" target={"_blank"} href="https://www.tripadvisor.it/Restaurant_Review-g2038950-d24057120-Reviews-JJC_Bar_And_Restaurant_Gede-Gede_Coast_Province.html?m=19905">
        <img src={trip} alt="image" />
      </a>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{wa_texts[lang_index.lang].title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="WA_Buttons_par">{wa_texts[lang_index.lang].par}</p>
          <div className="WA_Buttons__but_div">
            <a
              href="https://web.whatsapp.com/send/?phone=+254705245514"
              className="WA_Buttons__but"
              target="_blank"
            >
              + 254 705 245 514
              <img src={wabut} alt="image" />
            </a>
            <a
              href="https://web.whatsapp.com/send/?phone=+393895011722"
              className="WA_Buttons__but"
              target="_blank"
            >
              +39 389 501 1722
              <img src={wabut} alt="image" />
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {wa_texts[lang_index.lang].button_close}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WA_Button;
