import React from 'react';
import ReactDOM from 'react-dom/client';
import {Route, Routes, BrowserRouter} from "react-router-dom"
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducers from './Reducers';
import "./index.css"

import Home from './Pages/Home/Home';
import ChiSiamo from './Pages/ChiSiamo/ChiSiamo';
import Info from './Pages/Info/Info';
import Contattaci from './Pages/Contattaci/Contattaci';
import Galleria from './Pages/Galleria/Galleria';
import Servizi from './Pages/Servizi/Servizi';
import Safari from './Pages/Safari/Safari';
import ItemPage from './Components/ItemPage/ItemPage';
import PageNotFound from './Components/PageNotFound/PageNotFound';
import Escursioni from './Pages/Escursioni/Escursioni';
import Alloggi from './Pages/Alloggi/Alloggi';
import Ristorante from './Pages/Ristorante/Ristorante';

const store = createStore(rootReducers)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/chi_siamo" element={<ChiSiamo/>} />
        <Route exact path="/faq" element={<Info/>} />
        <Route exact path="/contattaci" element={<Contattaci/>} />
        <Route exact path="/galleria" element={<Galleria/>} />
        <Route exact path="/servizi" element={<Servizi/>} />
        <Route exact path="/safari" element={<Safari/>} />
        <Route exact path="/escursioni" element={<Escursioni/>} />
        <Route exact path="/alloggi" element={<Alloggi/>} />
        <Route exact path="/ristorante" element={<Ristorante/>} />
        <Route exact path="/safari/tickets/:ticket_index" element={<ItemPage type = "Safari" />} />
        <Route exact path="/escursioni/tickets/:ticket_index" element={<ItemPage type = "Escursioni" />} />
        {/* <Route exact path="/alloggi/tickets/:ticket_index" element={<ItemPage type = "Alloggi" />} /> */}
        <Route exact path="*" element={<PageNotFound/>} />
      </Routes>
    </BrowserRouter>
  </Provider>
);
