import React from "react";
import "./CSS/Ticket_CARD.css"

import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

const Ticket_CARD = (props) => {

    const navigate = useNavigate()
    
    const lang_index = useSelector(state => state.langReducer)
    const ticketcard_texts = require("./JSON/ticketcard_texts.json")

    return(
        <div className="Safari__ticket_card" onClick={() => navigate("/" + props.page + "/tickets/" + props.index)} >
            {/* <img src={props.img} alt="image" className="Safari__ticket_card_img" /> */}
            <div className="Safari__ticket_card_img" style={{ backgroundImage:"url('" + props.img + "')"}}>

            </div>
            <div className="Safari__ticket_card_text_div">
                <h1 className="Safari__ticket_card_title">
                    {props.title}
                </h1>
                <p className="Safari__ticket_card_par">
                    {props.par}
                </p>
                <p className="Safari__ticket_card_price">
                    {ticketcard_texts[lang_index.lang].price}: {props.price}
                </p>
            </div>
        </div>
    )
}

export default Ticket_CARD