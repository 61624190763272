const langReducer = (state = {lang : "IT"}, action) => { 
    switch(action.type){
        
        case "setLang":{
            return {lang : action.lang}
        }

        default: return state;
    }
}

export default langReducer;