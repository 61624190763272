import React from "react";
import "./CSS/Footer.css"
import visa_icon from "./MEDIA/visa.png"
import paypal from "./MEDIA/paypal.png"

import lime from "./MEDIA/lime.png"

const Footer = () => {
    return(
        <div className="Footer__main_div">
            <div className="Footer__second_div">
                
                <div className="Footer__par_div">
                    <p className="Footer_par">
                        © 2022 JJC Adventure Kenya. All Rights Reserved.
                    </p>
                    <p className="Footer_par">
                        Contattaci: + 254 705 245 514 <span>          </span> +39 389 501 1722
                    </p>
                    <p className="Footer_par">
                        Email: jjcadventure@gmail.com
                    </p>
                </div>
                <div className="Footer__paymnts_div">
                    <div className="Footer__paymnts_div_2">
                        <img src={visa_icon} alt="icon" className="Footer__paymnts_icon" />
                        <img src={paypal} alt="icon" className="Footer__paymnts_icon" />
                    </div>
                </div>
                <div className="Footer__paymnts_div">
                    <div className="Footer__paymnts_div_2">
                        <img src={lime} alt="lime_logo" className="Footer__lime_logo" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;