import React, {useEffect} from "react";
import "./CSS/Escursioni.css"

import NavWrapper from "../../Components/NavWrapper/NavWrapper"
import Navbar from "../../Components/Navbar/Navbar"
import Footer from "../../Components/Footer/Footer"
import Ticket_CARD from "../../Components/Ticket_CARD/Ticket_CARD"

import { useSelector } from "react-redux"

const Escursioni = () => {
    
    const lang_index = useSelector(state => state.langReducer)
    const escursioni_texts = require("./JSON/escursioni_texts.json")

    useEffect(() => {
        document.querySelector(".Navbar__main_div").scrollIntoView({ behavior: 'smooth' })
    }, [])
    
    return(
        <>
            <NavWrapper title = {escursioni_texts[lang_index.lang].navwraptext} image = "MEDIA/escursioni.webp">
                <Navbar/>
            </NavWrapper>
            <div className="Safari__main_div">
                <div className="Safari__title_div">
                    <h1 className="Safari__title">
                        {escursioni_texts[lang_index.lang].title}
                    </h1>
                    <p className="Safari__par">
                        {escursioni_texts[lang_index.lang].par}
                    </p>
                </div>
                <div className="Safari__second_div">
                    <Ticket_CARD index = {0} img = "MEDIA/Tickets/Escursioni/img_0.jpg" title = "ESCURSIONE A MARAFA HELL’S KITCHEN (MEZZA GIORNATA)" par = "" price = "€20.00" page = "escursioni" />
                    {/* <Ticket_CARD index = {1} img = "MEDIA/Tickets/Escursioni/img_1.jpg" title = "ESCURSIONE ALL’ISOLA DELL’AMORE" par = "" price = "€20.00" page = "escursioni" /> */}
                    <Ticket_CARD index = {2} img = "MEDIA/Tickets/Escursioni/img_2.jpg" title = "APERITIVO AL TRAMONTO ALLE MANGROVIE" par = "" price = "€20.00" page = "escursioni" />
                    <Ticket_CARD index = {3} img = "MEDIA/Tickets/Escursioni/img_3.jpg" title = "ESCURSIONE ALLE ROVINE DI GEDE (MEZZA GIORNATA)" par = "" price = "€20.00" page = "escursioni" />
                    <Ticket_CARD index = {4} img = "MEDIA/Tickets/Escursioni/img_4.jpg" title = "ESCURSIONE ISOLA DI ROBINSON E SPIAGGIA DORATA (GIORNATA INTERA)" par = "" price = "€20.00" page = "escursioni" />
                    <Ticket_CARD index = {5} img = "MEDIA/Tickets/Escursioni/img_5.jpg" title = "SAFARI BLU A MIDA CREEK (GIORNATA INTERA)" par = "" price = "€20.00" page = "escursioni" />
                    <Ticket_CARD index = {6} img = "MEDIA/Tickets/Escursioni/img_6.jpg" title = "SAFARI BLU A SARDEGNA 2 (GIORNATA INTERA)" par = "" price = "€20.00" page = "escursioni" />
                    <Ticket_CARD index = {7} img = "MEDIA/Tickets/Escursioni/img_7.jpg" title = "VISITA AL VILLAGGIO AFRICANO" par = "" price = "€20.00" page = "escursioni" />
                    <Ticket_CARD index = {8} img = "MEDIA/Tickets/Escursioni/img_8.jpg" title = "MALINDI E I SUOI ARTIGIANI (MEZZA GIORNATA)" par = "" price = "€20.00" page = "escursioni" />
                    <Ticket_CARD index = {9} img = "MEDIA/Tickets/Escursioni/img_9.jpg" title = "DHOW CRUISE GITA BARCA A VELA CON APERITIVO" par = "" price = "€20.00" page = "escursioni" />
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Escursioni