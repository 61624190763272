const cartReducer = (state = {total:0, products:[]}, action) => { //dovrebbe essere: {total:30, products:[{id_product:"00001", product_name:"Pollos" quantity:10, price:3, total_price:"30"}]}
    switch(action.type){
        
        case "setItem": {
            let dict = {...state}
            dict.products.push(action.newitem)
            dict.total = dict.total + (action.newitem.price * action.newitem.quantity)
            return {...dict}
        }

        case "addItems":{
            let dict = {...state}
            dict.products[action.index].quantity += action.quantity
            dict.products[action.index].total_price += (dict.products[action.index].price * action.quantity)
            dict.total = dict.total + (dict.products[action.index].price * action.quantity)
            return {...dict}
        }

        case "removeItems":{
            let dict = {...state}
            dict.products[action.index].quantity -= action.quantity
            dict.products[action.index].total_price -= (dict.products[action.index].price * action.quantity)
            dict.total = dict.total - (dict.products[action.index].price * action.quantity)
            return {...dict}
        }

        case "removeItem":{
            let dict = {...state}
            dict.total = dict.total - dict.products[action.index].total_price
            dict.products.splice(action.index, 1)
            return {...dict}
        }

        case "resetALL":{
            return {total:0, products:[]}
        }
        
        default: return state;
    }
}

export default cartReducer;