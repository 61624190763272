import React, {useEffect} from "react";
import "./CSS/Alloggi.css"

import parse from 'html-react-parser';
/* import Carousel from 'react-bootstrap/Carousel'; */
import 'bootstrap/dist/css/bootstrap.min.css';

import NavWrapper from "../../Components/NavWrapper/NavWrapper"
import Navbar from "../../Components/Navbar/Navbar"
import Footer from "../../Components/Footer/Footer"
/* import Ticket_CARD from "../../Components/Ticket_CARD/Ticket_CARD" */

import LightBox from "../../Components/LightBox/LightBox";

import { useSelector } from "react-redux"

const Alloggi = () => {

    const lang_index = useSelector(state => state.langReducer)
    const alloggi_texts = require("./JSON/alloggi_texts.json")

    const images = [
        [
            {image_path:"MEDIA/ALL/1.jpeg", index:0},
            {image_path:"MEDIA/ALL/2.jpeg", index:1},
            {image_path:"MEDIA/ALL/3.jpeg", index:2},
        ],
        [
            {image_path:"MEDIA/ALL/13.jpeg", index:3},
            {image_path:"MEDIA/ALL/4.jpeg", index:4},
            {image_path:"MEDIA/ALL/5.jpeg", index:5},
        ],
        [
            {image_path:"MEDIA/ALL/7.jpeg", index:6},
            {image_path:"MEDIA/ALL/8.jpeg", index:7},
            {image_path:"MEDIA/ALL/9.jpeg", index:8},
        ],
        [
            {image_path:"MEDIA/ALL/6.jpeg", index:9},
            {image_path:"MEDIA/ALL/11.jpeg", index:10},
            {image_path:"MEDIA/ALL/12.jpeg", index:11},
        ]
    ]

    const images2 = [
        [
            /* {image_path:"MEDIA/ALL/AFR/1.jpeg", index:1}, */
            /* {image_path:"MEDIA/ALL/AFR/6.jpeg", index:6}, */
            {image_path:"MEDIA/ALL/AFR/3.jpeg", index:0},
            {image_path:"MEDIA/ALL/AFR/5.jpeg", index:1},
            
        ],
        [
            
            {image_path:"MEDIA/ALL/AFR/4.jpeg", index:2},
            {image_path:"MEDIA/ALL/AFR/7.jpeg", index:3},
        ],
        [
            
            {image_path:"MEDIA/ALL/AFR/2.jpeg", index:4},
        ],
        [
            
            {image_path:"MEDIA/ALL/AFR/8.jpeg", index:5},
        ]
    ]

    useEffect(() => {
        document.querySelector(".Navbar__main_div").scrollIntoView({ behavior: 'smooth' })
    }, [])

    return(
        <>
            <NavWrapper title = {alloggi_texts[lang_index.lang].navwraptext} image = "MEDIA/alloggi.webp">
                <Navbar/>
            </NavWrapper>
            <div className="Safari__main_div">
                <div className="Alloggi__title_div">
                    <h1 className="Safari__title">
                        {alloggi_texts[lang_index.lang].title}
                    </h1>
                    {parse(alloggi_texts[lang_index.lang].par)}
                </div>

                <LightBox images = {images} />

                <div className="Alloggi__title_div">
                    <h1 className="Safari__title">
                        {alloggi_texts[lang_index.lang].title2}
                    </h1>
                    {parse(alloggi_texts[lang_index.lang].par3)}
                </div>

                <LightBox images = {images2} />
                
                {/* <div style={{"width":"80%", "height":"auto", "display":"flex", marginBottom:"100px"}}>
                    <Carousel>
                        
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="MEDIA/chi_siamo_img_1.jpg"
                                alt="Second slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                </div> */}
                
                {/* <div className="Safari__second_div">
                    <Ticket_CARD index = {0} img = "MEDIA/Tickets/Alloggi/img_0.jpg" title = "Camera Deluxe AMBOSELI" par = {alloggi_texts[lang_index.lang].par2} price = "€80.00" page = "alloggi" />
                    <Ticket_CARD index = {1} img = "MEDIA/Tickets/Alloggi/img_1.jpg" title = "Camera Deluxe MASAI MARA" par = {alloggi_texts[lang_index.lang].par2} price = "€80.00" page = "alloggi" />
                    <Ticket_CARD index = {2} img = "MEDIA/Tickets/Alloggi/img_2.jpg" title = "Camera Deluxe TAITA" par = {alloggi_texts[lang_index.lang].par2} price = "€80.00" page = "alloggi" />
                    <Ticket_CARD index = {3} img = "MEDIA/Tickets/Alloggi/img_3.jpg" title = "Camera Deluxe TSAVO" par = {alloggi_texts[lang_index.lang].par2} price = "€80.00" page = "alloggi" />
                </div> */}

                <div className="Alloggi__title_div">
                    <h1 className="Safari__title">
                        {alloggi_texts[lang_index.lang].title3}
                    </h1>
                    {parse(alloggi_texts[lang_index.lang].par4)}
                    {parse(alloggi_texts[lang_index.lang].par5)}
                    {parse(alloggi_texts[lang_index.lang].par6)}
                    <h5>{parse(alloggi_texts[lang_index.lang].tel1)}</h5>
                    <h5>{parse(alloggi_texts[lang_index.lang].tel2)}</h5>
                </div>

            </div>
            <Footer/>
        </>
    )
}

export default Alloggi