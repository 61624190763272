import "./CSS/Ban.css";

const Ban = () => {
  const handleClick = () => {
    localStorage.setItem("AAA", "BBB");
    document.querySelector(".Ban__main_div").style.display = "none";
  };

  if (localStorage.getItem("AAA") === null) {
    return (
      <div className="Ban__main_div">
        <div className="Ban__banner_main_div">
          <p className="Ban__text">
            The website only uses essential cookies for the proper functioning
            of the site.
          </p>
          <div className="Ban__buttons_div">
            {/* <button>Accept</button>
          <button>Decline</button> */}
            <button onClick={handleClick}>I understand</button>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Ban;
